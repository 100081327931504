import { CSSTransition } from 'react-transition-group'
export default function Modal({ visible, confirmOnClose, close, children, noBgClose, noCloseBtn, noPadding, title, showPreviousBtn, previous, noRounded, noBgColor, fullHeightCenter, bgMask }) {
  const [key] = useState(uuid())
  const t = useI18n()
  const { zIndexResolver } = usePanel()
  const zIndex = zIndexResolver(key, visible)
  store.autoClose.register({ key, visible, close })
  useEffect(() => () => {
    store.autoClose.register({ key, visible: false, close })
  }, [])
  const { current } = useLoading()
  const { fromApp } = useCurrent()
  const onClose = () => {
    if (current) return
    if (confirmOnClose && !confirm(t('common:modalOnClose'))) return
    close()
  }
  const onPrevious = () => {
    if (current) return
    previous()
  }
  const duration = 100
  const ref = useRef(null)

  const rounded = () => {
    if (noRounded) return ''
    return 'sm:rounded-xl'
  }

  const bgColor = () => {
    if (noBgColor) return ''
    return 'bg-white shadow-md'
  }

  const maxHeight = () => {
    if (fullHeightCenter) return ''
    return 'sm:max-h-[40rem]'
  }

  const flexElementTop = () => {
    if (fullHeightCenter) return
    return <div className='flex-auto max-h-[3rem]' />
  }

  const flexElementBottom = () => {
    if (fullHeightCenter) return null
    return <div className='flex-1' />
  }

  const contentFlex = () => {
    if (fullHeightCenter) return 'flex-1'
    return ''
  }

  const renderBgStyle = () => {
    if (!bgMask) return 'bg-black bg-opacity-20'
    return bgMask
  }

  const appPadding = () => {
    if (fromApp) return 'p-5'
    return ''
  }

  return (
    <>
      <div className={ cn({ hidden: !visible }) }>
        <div className={ cn('fixed inset-0', renderBgStyle()) }
          style={ { zIndex } }
        />
      </div>
      <div
        className={ cn('fixed inset-0 flex', { hidden: !visible })
        }
        style={ { zIndex } }
        onClick={ () => {
          if (noBgClose) return
          onClose()
        } }
      >
        <CSSTransition
          in={ visible }
          classNames='slide-up'
          timeout={ duration }
          nodeRef={ ref }
        >
          <div className={ cn('flex justify-center items-center inset-0 flex-col w-full', appPadding()) }>
            { flexElementTop() }
            <div
              className={ cn('h-full w-full relative max-h-screen max-w-full overflow-hidden sm:w-auto  sm:h-auto', contentFlex(), rounded(), bgColor(), maxHeight()) }
              onClick={ e => e.stopPropagation() }
              ref={ ref }
            >
              <div className={ cn('h-full', { 'p-7 pt-3': !noPadding }) }>
                {
                  title && (
                    <div className='text-xl font-bold pb-7 pt-5'>
                      { title }
                    </div>
                  )
                }
                <div className='modal-body-wrapper h-full'>
                  { visible ? children : '' }
                </div>
              </div>
              <div className={ cn({ hidden: !showPreviousBtn }) }>
                <C.ButtonIcon
                  className='absolute text-gray-400 top-2'
                  onClick={ onPrevious }
                >
                  <Icon.ChevronLeft />
                </C.ButtonIcon>
              </div>
              <div className={ cn({ hidden: noCloseBtn }) }>
                <C.ButtonIcon
                  className='absolute text-gray-400 right-0 top-2'
                  onClick={ onClose }
                >
                  <Icon.Close />
                </C.ButtonIcon>
              </div>
            </div>
            { flexElementBottom() }
          </div>
        </CSSTransition>
      </div>
      <style jsx>{`
        .slide-up-enter {
          opacity: 0;
          transform: scale(0.75);
        }
        .slide-up-enter-active {
          opacity: 1;
          transform: scale(1);
          transition-duration: ${duration}ms;
        }
        .slide-up-exit {
          opacity: 1;
        }
        .slide-up-exit-active {
          opacity: 0;
          transition-duration: ${duration}ms;
        }
      `}</style>
    </>
  )
}
